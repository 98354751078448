import { Navigate, Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import LazySlider from '@components/custom-loader/LazySlider';
import TwoFactorAuthenticationStepper from '../common-features/setting/pages/two-factor authentication-stepper/TwoFactorAuthenticationStepper';

const OrganizationList = React.lazy(
    () => import('../client-features/organization/pages/organization-list/OrganizationList'),
);

const DeviceUpdateNew = React.lazy(
    () => import('@client-features/organization/pages/device/device-new-update/DeviceNewUpdate'),
);

const OrganizationDetails = React.lazy(
    () => import('../client-features/organization/pages/organization-details/OrganizationDetails'),
);

const OrganizationUpdate = React.lazy(
    () => import('../client-features/organization/pages/organization-update/OrganizationUpdate'),
);

const EntityDetails = React.lazy(
    () => import('../client-features/organization/pages/entity/entity-details/EntityDetails'),
);

const CsidGenerate = React.lazy(
    () => import('../client-features/organization/pages/csid/csid-generate/CsidGenerate'),
);

const EntityCreate = React.lazy(
    () => import('../client-features/organization/pages/entity/entity-create/EntityCreate'),
);

const CsidDetails = React.lazy(
    () => import('../client-features/organization/pages/csid/csid-details/CsidDetails'),
);

const DeviceCreate = React.lazy(
    () => import('../client-features/organization/pages/device/device-create/DeviceCreate'),
);

const DeviceDetails = React.lazy(
    () => import('../client-features/organization/pages/device/device-details/DeviceDetails'),
);

const DeviceUpdate = React.lazy(
    () => import('../client-features/organization/pages/device/device-update/DeviceUpdate'),
);

const LayoutWrapper = React.lazy(
    () => import('@components/main-layout/layout-wrapper/LayoutWrapper'),
);

const CustomerList = React.lazy(
    () => import('../client-features/customer/pages/customer-list/CustomerList'),
);

const CustomerDetails = React.lazy(
    () => import('../client-features/customer/pages/customer-details/CustomerDetails'),
);
const CustomerCreate = React.lazy(
    () => import('../client-features/customer/pages/customer-create/CustomerCreate'),
);

const InventoryStepper = React.lazy(
    () => import('../client-features/inventory/inventory-stepper/InventoryStepper'),
);

const InventoryList = React.lazy(
    () => import('../client-features/inventory/inventory-list/InventoryList'),
);

const InventoryCreate = React.lazy(
    () => import('../client-features/inventory/inventory-create/InventoryCreate'),
);

const NewInvoice = React.lazy(
    () => import('../client-features/e-invoice/pages/new-invoice/NewInvoice'),
);

const InvoiceList = React.lazy(
    () => import('../client-features/e-invoice/pages/invoice-list/InvoiceList'),
);
const DraftInvoiceList = React.lazy(
    () => import('../client-features/e-invoice/pages/draft-invoice-list/DraftInvoiceList'),
);
const Preference = React.lazy(
    () => import('../client-features/preference/preference-tabs/PreferenceTabs'),
);
const OrganizationEntityUpdate = React.lazy(
    () =>
        import(
            '../client-features/organization/components/organization-entity-update/OrganizationEntityUpdate'
        ),
);
const AddDevices = React.lazy(
    () => import('../client-features/organization-setup/pages/add-devices/AddDevices'),
);
const EinvoicePhase = React.lazy(
    () => import('../client-features/organization-setup/pages/einvoice-phase/EinvoicePhase'),
);

const OrganizationSetupCompleted = React.lazy(
    () =>
        import(
            '../client-features/organization-setup/pages/organization-setup-completed/OrganizationSetupCompleted'
        ),
);

const AddOrganization = React.lazy(
    () => import('../client-features/organization-setup/pages/add-organization/AddOrganization'),
);

const AddEntity = React.lazy(
    () => import('../client-features/organization-setup/pages/add-entity/AddEntity'),
);
const OrganizationSetUpRoot = React.lazy(
    () =>
        import(
            '../client-features/organization-setup/pages/organization-setup-root/OrganizationSetUpRoot'
        ),
);
const OrganizationInitiate = React.lazy(
    () =>
        import(
            '../client-features/organization-setup/pages/organization-initiate/OrganizationInitiate'
        ),
);
// import { CreateTicket, MyTicket, SupportHome } from '../client-features/support';
// import { SettingTabs, TwoFactorAuthenticationStepper } from '../client-features/setting';

const Profile = React.lazy(() => import('../client-features/profile/pages/Profile'));

const Subscribe = React.lazy(
    () => import('../client-features/subscribe/pages/subscribe/Subscribe'),
);

const CancelAutoRenewal = React.lazy(
    () => import('../client-features/subscribe/pages/cancel-auto-renewal/CancelAutoRenewal'),
);

const PricingPlans = React.lazy(
    () => import('../client-features/subscribe/pages/pricing-plans/PricingPlans'),
);

const SubscribeSuccess = React.lazy(
    () => import('../client-features/subscribe/pages/subscribe-success/SubscribeSuccess'),
);

const ManageSubscription = React.lazy(
    () => import('../client-features/subscribe/pages/manage-subscription/ManageSubscription'),
);

const PlanComparison = React.lazy(
    () => import('../client-features/subscribe/pages/plan-comparison/PlanComparison'),
);

const UploadInvoiceDetails = React.lazy(
    () =>
        import(
            '../client-features/upload-invoices/pages/upload-invoice-details/UploadInvoiceDetails'
        ),
);

const UploadInvoiceList = React.lazy(
    () => import('../client-features/upload-invoices/pages/upload-invoice-list/UploadInvoiceList'),
);

const UploadInvoiceStepper = React.lazy(
    () => import('../client-features/upload-invoices/pages/upload-stepper/UploadStepper'),
);

const ConfirmUploadInvoice = React.lazy(
    () =>
        import(
            '../client-features/upload-invoices/pages/confirm-upload-invoice/ConfirmUploadInvoice'
        ),
);

const CreateService = React.lazy(
    () => import('../client-features/api-services/pages/create/CreateService'),
);
const ApiServicesList = React.lazy(
    () => import('../client-features/api-services/pages/list/ApiServicesList'),
);

const SubscribeInvoiceDetails = React.lazy(
    () =>
        import(
            '../client-features/subscribe/pages/subscribe-invoice-details/SubscribeInvoiceDetails'
        ),
);
const PricingCalculator = React.lazy(
    () => import('../client-features/pricing-calculator/pages/PricingCalculator'),
);
const Dashboard = React.lazy(() => import('../client-features/dashboard/Dashboard'));

const InvoiceNewDetails = React.lazy(
    () => import('@client-features/e-invoice/pages/invoice-new-details/InvoiceNewDetails'),
);

const WhatsNew = React.lazy(() => import('../common-features/whats-new/pages/whats-new/WhatsNew'));

const SettingTabs = React.lazy(
    () => import('../common-features/setting/pages/setting-tabs/SettingTabs'),
);
export default function ClientRouter() {
    return (
        <Suspense fallback={<LazySlider />}>
            <Routes>
                <Route
                    path='/organization-setup/:organizationId'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <OrganizationSetUpRoot />
                        </Suspense>
                    }
                >
                    <Route
                        path='/organization-setup/:organizationId/organization-information'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <AddOrganization />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/organization-setup/:organizationId/entity'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <AddEntity />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/organization-setup/:organizationId/invoice-phase'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <EinvoicePhase />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/organization-setup/:organizationId/device'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <AddDevices />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/organization-setup/:organizationId/complete'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <OrganizationSetupCompleted />
                            </Suspense>
                        }
                    ></Route>
                </Route>
                <Route
                    path='/organization-initiate'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <OrganizationInitiate />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='organizations/create'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <OrganizationInitiate />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='subscribe/pricing-plans'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <PricingPlans />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='/subscribe/pricing-calculator'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <PricingCalculator />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='subscribe/manage-subscription'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <ManageSubscription />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='plan-comparison'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <PlanComparison />
                        </Suspense>
                    }
                ></Route>
                <Route
                    path='subscribe/subscribe-success'
                    element={
                        <Suspense fallback={<LazySlider />}>
                            <SubscribeSuccess />
                        </Suspense>
                    }
                ></Route>
                <Route path='' element={<LayoutWrapper />}>
                    <Route
                        path='subscribe'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <Subscribe />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='subscribe/invoice/:invoiceId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <SubscribeInvoiceDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='subscribe/cancel-auto-renewal'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <CancelAutoRenewal />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='dashboard'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <Dashboard />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='organizations'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <OrganizationList />
                            </Suspense>
                        }
                    ></Route>

                    <Route
                        path='organizations/update/:organizationId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <OrganizationUpdate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='organizations/:organizationId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <OrganizationDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='profile'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <Profile />
                            </Suspense>
                        }
                    ></Route>

                    <Route
                        path='organizations/:organizationId/entity/create'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <EntityCreate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='entity/create'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <EntityCreate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='organizations/csid/generate'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <CsidGenerate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='organizations/:organizationId/entity/:entityId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <EntityDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='organizations/entity/update/:entityId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <OrganizationEntityUpdate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='organizations/csid/:id'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <CsidDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='customers'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <CustomerList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='customers/create'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <CustomerCreate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='customers/update/:id'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <CustomerCreate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='customers/details/:id'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <CustomerDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='upload-invoices'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <UploadInvoiceStepper />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='confirm-upload-invoice'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <ConfirmUploadInvoice />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='upload-invoices/list'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <UploadInvoiceList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='upload-invoices/details/:id'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <UploadInvoiceDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='draft-invoices'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <DraftInvoiceList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='inventory-stepper'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <InventoryStepper />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='items'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <InventoryList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='items/create'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <InventoryCreate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='items/details'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <InventoryList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='items/update/:id'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <InventoryCreate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='setting/two-factor-authentication'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <TwoFactorAuthenticationStepper />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='setting'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <SettingTabs />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='preference'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <Preference />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='e-invoices'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <InvoiceList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='e-invoices/create'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <NewInvoice />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='create-credit-note'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <NewInvoice />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='create-debit-note'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <NewInvoice />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='create-simplified-credit-note'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <NewInvoice />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='create-simplified-debit-note'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <NewInvoice />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='e-invoices/details/:invoiceId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <InvoiceNewDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='organizations/:organizationId/device/create'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <DeviceCreate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='organizations/:organizationId/device/update/:deviceId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <DeviceUpdate />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='organizations/:organizationId/device/:action/:deviceId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <DeviceUpdateNew />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='organizations/:organizationId/device/:action/:deviceId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <DeviceUpdateNew />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='organizations/:organizationId/device/:deviceId'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <DeviceDetails />
                            </Suspense>
                        }
                    ></Route>
                    <Route path='/' element={<Navigate replace to='/dashboard' />}></Route>

                    {/*<Route*/}
                    {/*    path='/support'*/}
                    {/*    element={*/}
                    {/*        <Suspense fallback={<LazySlider />}>*/}
                    {/*            <SupportHome />*/}
                    {/*        </Suspense>*/}
                    {/*    }*/}
                    {/*></Route>*/}
                    {/*<Route*/}
                    {/*    path='/support/my-ticket'*/}
                    {/*    element={*/}
                    {/*        <Suspense fallback={<LazySlider />}>*/}
                    {/*            <MyTicket />*/}
                    {/*        </Suspense>*/}
                    {/*    }*/}
                    {/*></Route>*/}
                    {/*<Route*/}
                    {/*    path='/support/create-ticket'*/}
                    {/*    element={*/}
                    {/*        <Suspense fallback={<LazySlider />}>*/}
                    {/*            <CreateTicket />*/}
                    {/*        </Suspense>*/}
                    {/*    }*/}
                    {/*></Route>*/}
                    <Route
                        path='/api-services'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <ApiServicesList />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/api-services/create'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <CreateService />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/api-services/update/:id'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <CreateService />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/api-services/details/:id'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <CreateService />
                            </Suspense>
                        }
                    ></Route>
                    <Route
                        path='/whats-new'
                        element={
                            <Suspense fallback={<LazySlider />}>
                                <WhatsNew />
                            </Suspense>
                        }
                    ></Route>
                </Route>
            </Routes>
        </Suspense>
    );
}
